import 'core-js/stable';
import 'regenerator-runtime/runtime';
import debounce from 'lodash/debounce';
import '../scss/main.scss';
import Lazyload from 'vanilla-lazyload';
import StickySidebar from 'sticky-sidebar-v2/dist/sticky-sidebar';
import Tabs from 'vanilla-js-tabs';

function ready(fn) {
    if (document.readyState != 'loading'){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function() {

    new Lazyload({
        elements_selector: '.lazy',
        use_native: true
    });

    var myTabs = document.querySelector('#js-schedule-tabs');
    if(myTabs) {
        var myTabsInst = new Tabs(myTabs, {
            tablist: '.js-schedule-tablist',
            tab: '.js-schedule-tab',
            panel: '.js-schedule-panel',
            prefix: 'Programma-Giornata-'
        });
        // First Tab
        // myTabsInst.activate(0);
        // Second Tab
        // myTabsInst.activate(1);
        // Third Tab
        // myTabsInst.activate(2);
        // Fourth Tab
        // myTabsInst.activate(3);
        // etc.
    }

    const body = document.querySelector('body');
    const myTimers = window.document.querySelectorAll('.js-timer');
    var myTimerD, myTimerH, myTimerM, myTimerS;
    function setValue(el, value) {
        el.innerHTML = value;
    }
    function calcTimer(timerData) {
        const current = new Date();
        var remainingTotal = Date.parse(timerData) - Date.parse(current);
        var remainingDays = Math.floor( remainingTotal/(1000*60*60*24) );
        var remainingHours = Math.floor( (remainingTotal/(1000*60*60)) % 24 );
        var remainingMinutes = Math.floor( (remainingTotal/1000/60) % 60 );
        var remainingSeconds = Math.floor( (remainingTotal/1000) % 60 );
              
        return {
            remainingTotal,
            remainingDays,
            remainingHours,
            remainingMinutes,
            remainingSeconds
        };
    }
    function initTimer(timerData) {
        let calcResults = calcTimer(timerData);
        myTimers.forEach(function(timer) {
            myTimerD = timer.querySelector('.js-timer-d');
            myTimerH = timer.querySelector('.js-timer-h');
            myTimerM = timer.querySelector('.js-timer-m');
            myTimerS = timer.querySelector('.js-timer-s');

            if (calcResults.remainingTotal > 0) {
                if(myTimerD)
                    setValue(myTimerD, calcResults.remainingDays);
                setValue(myTimerH, calcResults.remainingHours);
                setValue(myTimerM, calcResults.remainingMinutes);
                setValue(myTimerS, calcResults.remainingSeconds);
            } else {
                if(myTimerD)
                    setValue(myTimerD, 0);
                setValue(myTimerH, 0);
                setValue(myTimerM, 0);
                setValue(myTimerS, 0);
            }
        });

        const timerLoop = setInterval(() => {
            calcResults = calcTimer(timerData);
            myTimers.forEach(function(timer) {
                myTimerD = timer.querySelector('.js-timer-d');
                myTimerH = timer.querySelector('.js-timer-h');
                myTimerM = timer.querySelector('.js-timer-m');
                myTimerS = timer.querySelector('.js-timer-s');
    
                if (calcResults.remainingTotal > 0) {
                    if(myTimerD)
                        setValue(myTimerD, calcResults.remainingDays);
                    setValue(myTimerH, calcResults.remainingHours);
                    setValue(myTimerM, calcResults.remainingMinutes);
                    setValue(myTimerS, calcResults.remainingSeconds);
                } else {
                    if(myTimerD)
                        setValue(myTimerD, 0);
                    setValue(myTimerH, 0);
                    setValue(myTimerM, 0);
                    setValue(myTimerS, 0);
                }
            });
            if (calcResults.remainingTotal <= 0) {
                clearInterval(timerLoop);
            }
        }, 1000);
    }

    const myWatches = window.document.querySelectorAll('.js-watch');
    var myWatchH, myWatchM, myWatchS;
    function rotateWatch(el, teta) {
        el.style.webkitTransform = 'translateZ(0px) rotateZ('+teta+'deg)'; 
        el.style.mozTransform    = 'translateZ(0px) rotateZ('+teta+'deg)'; 
        el.style.msTransform     = 'translateZ(0px) rotateZ('+teta+'deg)'; 
        el.style.oTransform      = 'translateZ(0px) rotateZ('+teta+'deg)'; 
        el.style.transform       = 'translateZ(0px) rotateZ('+teta+'deg)';
    }
    function initWatch() {
        const currentTime = new Date();
        const currentOffset = currentTime.getTimezoneOffset();
        const currentTimeTotal = Date.parse(currentTime) - (currentOffset*(1000*60));
        const currentTetaHours = .5 * Math.floor( (currentTimeTotal/(1000*60)) % 720 );
        const currentTetaMinutes = 6 * Math.floor( (currentTimeTotal/1000/60) % 60 );
        const currentTetaSeconds = 6 * Math.floor( (currentTimeTotal/1000) % 60 );

        myWatches.forEach(function(watch) {
            myWatchH = watch.querySelector('.js-watch-h');
            myWatchM = watch.querySelector('.js-watch-m');
            myWatchS = watch.querySelector('.js-watch-s');

            rotateWatch(myWatchH, currentTetaHours);
            rotateWatch(myWatchM, currentTetaMinutes);
            rotateWatch(myWatchS, currentTetaSeconds);
        });
    }
    
    const myStickySidebar = document.getElementById('js-sticky-sidebar');
    function initStickySidebar() {
        var myStickySidebarInst = new StickySidebar(myStickySidebar, {
            containerSelector: '#js-sticky-sidebar-container',
            innerWrapperSelector: '#js-sticky-sidebar-inner',
            stickyClass: 'is-fixed',
            topSpacing: 32,
        });
        function fixStickySidebar() {
            myStickySidebarInst.updateSticky();
        }
        // IE bugfix hack
        myStickySidebar.addEventListener('affixed.top.stickySidebar', debounce(fixStickySidebar, 550, {
            'leading': false,
            'trailing': true
          })
        );
    }

    const myTopBar = document.getElementById('js-top-bar');
    const myTriggerEl = document.getElementById('js-top-bar-trigger');
    var myInitialPosY, myScrollY, myTriggerRect, myTriggerY;
    function scrollTo(el) {
        el.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
        });
    }
    function calcTopBar() {
        myScrollY = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        myScrollY += myTopBar.offsetHeight;
    }
    function updateTopBar() {
        calcTopBar();
        myTopBar.classList[myScrollY > (myInitialPosY + myTriggerY) ? 'add' : 'remove']('is-fixed');
    }
    function initTopBar() {
        myTriggerRect = myTriggerEl.getBoundingClientRect();
        myTriggerY = myTriggerRect.top;
        myInitialPosY = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        myScrollY = myInitialPosY;
        myScrollY += myTopBar.offsetHeight;

        window.addEventListener('scroll', function() {
            updateTopBar();
        });

        myTopBar.querySelector('a').addEventListener('click', function(e) {
            e.preventDefault();
            scrollTo(myStickySidebar);
        });

        updateTopBar();
    }

    const myScrollContainers = window.document.querySelectorAll('.js-carousel');
    var myScrollInner;
    var myScrollItems = [];
    var myScrollClones = [];
    var myScrollWidth, myScrollPos, myClonesWidth, myTargetScrollPos;
    var animatingCarousel = undefined;
    function scrollLeftTo(el, to) {
        animatingCarousel = undefined;
        var diff = to - el.scrollLeft;
        var perTick = 1;

        if (diff <= 0) return;

        if(!animatingCarousel) {
            animatingCarousel = window.requestAnimationFrame(function(timestamp){
                scrollLeftTo(el, to);
            });
        }

        if(animatingCarousel) {
            setScrollLeftPos(el, el.scrollLeft + perTick);
        }
    }
    function setScrollLeftPos(el, pos) {
        el.scrollLeft = pos;
    }
    function getScrollPos(el) {
        return el.scrollLeft;
    }
    function getScrollWidth(el) {
        return el.querySelector('.js-carousel-inner').offsetWidth;
    }
    function getClonesWidth(el) {
        myClonesWidth = 0;
        myScrollClones = el.querySelectorAll('.js-carousel-clone');

        myScrollClones.forEach(function(clone){
            if((clone.offsetWidth > 0)){
                myClonesWidth = myClonesWidth + clone.offsetWidth;
            }
        });

        return myClonesWidth;
    }
    function calcScroll(el) {
        if((el.offsetWidth > 0)) {
            myScrollPos = getScrollPos(el);
            myScrollWidth = getScrollWidth(el);
            myClonesWidth = getClonesWidth(el);
        }
    }
    function startScroll(el) {
        calcScroll(el);
        myTargetScrollPos = myScrollWidth - myClonesWidth;

        window.requestAnimationFrame(function(timestamp){
            scrollLeftTo(el, myTargetScrollPos);
        });
    }
    function stopScroll() {
        if(animatingCarousel) {
            window.cancelAnimationFrame(animatingCarousel);
            animatingCarousel = undefined;
        }
    }
    function restartScroll(el) {
        setScrollLeftPos(el, 0);
    }
    function updateScroll() {
        myScrollContainers.forEach(function(container) {
            calcScroll(container);
            if (myClonesWidth + myScrollPos >= myScrollWidth) {
                restartScroll(container);
            }
        });
    }
    function initScroll() {
        myScrollContainers.forEach(function(container) {
            myScrollInner = container.querySelector('.js-carousel-inner');
            myScrollItems = container.querySelectorAll('.js-carousel-item');

            myScrollItems.forEach(function(item) {
                const clone = item.cloneNode(true);
                myScrollInner.appendChild(clone);
                clone.classList.add('js-carousel-clone');
            });
            
            setScrollLeftPos(container, 0);

            container.addEventListener('scroll', function() {
                updateScroll();
            }, false);

            startScroll(container);
        });
        
        window.addEventListener('resize', function() {
            myScrollContainers.forEach(function(container) {
                restartScroll(container);
            });
        }, false);

        const mutationObserver = new MutationObserver(function(mutationsList){
            mutationsList.forEach(mutation => {
                if((mutation.attributeName === 'backgroundAdv')||(mutation.attributeName === 'backgroundAdvTest')) {
                    myScrollContainers.forEach(function(container) {
                        restartScroll(container);
                    });
                }
            })
        });
        mutationObserver.observe(
            body,
            { attributes: true }
        )
    }

    const myToTop = document.getElementById('js-to-top');
    var animatingTop = undefined;
    function scrollTopTo(el, to) {
        animatingTop = undefined;
        var diff = to - el.scrollTop;
        var perTick = 150;

        if (diff >= 0) {
            window.cancelAnimationFrame(animatingTop);
            return;
        }

        if(!animatingTop) {
            animatingTop = window.requestAnimationFrame(function(timestamp){
                scrollTopTo(el, to);
            });
        }

        if(animatingTop) {
            setScrollTopPos(el, el.scrollTop - perTick);
        }
    }
    function setScrollTopPos(el, pos) {
        el.scrollTop = pos;
    }
    function initToTop() {
        myToTop.addEventListener('click', function() {
            if(!animatingTop) {
                window.requestAnimationFrame(function() {scrollTopTo(document.body, 0)});
                window.requestAnimationFrame(function() {scrollTopTo(document.documentElement, 0)});
            }
        });
    }

    const dropdownLinks = window.document.querySelectorAll('.header__user__link');
    function initDropdownLinks() {
        dropdownLinks.forEach(function(dropdownLink) {
            dropdownLink.onclick = function () {
                let myDropdownEl = dropdownLink.parentNode.querySelector('.header__user__dropdown');
                if(myDropdownEl) {
                    if (myDropdownEl.classList.contains("show")) {
                        myDropdownEl.style.display = "none";
                        myDropdownEl.classList.toggle("show");
                    } else {
                        myDropdownEl.style.display = "block";
                        myDropdownEl.classList.toggle("show");
                    }
                }
            }
        });
    }
    const dropdownEls = window.document.querySelectorAll('.header__user__dropdown');
    function initDropdownEls() {
        dropdownEls.forEach(function(dropdownEl) {
            document.addEventListener('click', function(event) {
                var clickedUserDetails = (dropdownEl.parentNode.contains(event.target));
                
                if (!clickedUserDetails) {
                    dropdownEl.style.display = "none";
                    dropdownEl.classList.remove("show");
                }
            });
        });
    }
    function initDropdowns() {
        initDropdownLinks();
        initDropdownEls();
    }

    const introBanner = document.querySelector('.intro-banner');

    // Launch when DOM has been loaded
    if(eventDeadline) {
        initTimer(eventDeadline);
        initWatch();
    }
    if(dropdownLinks && dropdownEls) {
        initDropdowns();
    }
    if(!introBanner) {
        body.classList.add("hasnt-intro-banner");
    }
    if(myToTop) {
        initToTop();
    }

    // Launch when everything has been loaded
    window.onload = function() {
        if(myStickySidebar) {
            initStickySidebar();
        }
        if(myTopBar && myTriggerEl) {
            initTopBar();
        }
        if(myScrollContainers) {
            initScroll();
        }
    }
});